export class ErrorHandler {
	static handleError(error: any): string {
		if (!error?.response) {
			if (error?.code === "ERR_NETWORK") {
				return "A network error has occurred due to FPT's policy, which restricts file uploads and access to services external to the FPT internal network. Please contact your system administrator if you believe this is an error.";
			}
			return "An unexpected error has occurred. Please try again. If the problem persists, contact support.";
		}

		const { status, data } = error.response;

		if (data?.detail === "Entity does not exist in LCM Database") {
			return "The Entity does not exist in LCM Database. Please check again on FCMS side or your input. Or contact to LCM User for more detail.";
		}

		if (
			status === 403 ||
			status === 503 ||
			data?.detail?.includes("FILE_TRANSFER_BLOCKED")
		) {
			return "The file you are trying to download or upload has been blocked by company policy. Please contact your system administrator if you believe this is an error.";
		} else if (status === 401) {
			return "Session view is expired. Please back to FCMS page to create a new session.";
		} else if (data?.detail?.includes("RATE_LIMIT_EXCEEDED")) {
			return "The system is currently experiencing a high volume of requests. Please try again later or consider upgrading your AI pricing tier to handle more requests.";
		} else if (data?.detail?.includes("INVALID_BASE64")) {
			return "The file could not be processed due to an encoding issue. Please ensure the file is correctly encoded and try again.";
		} else if (data?.detail?.includes("FILE_TYPE_MISMATCH")) {
			return "The uploaded file type does not match the expected format. Please verify the file and try again.";
		}
		
		return "An unexpected error has occurred. Please try again. If the problem persists, contact support.";
	}
}
